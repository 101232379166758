/* AddSteps.css */
.step-number {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: rgb(33, 150, 243);
    color: white;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
  }
  
  .demoCustomLine.vertical-timeline{
    width: 100% !important;
    max-width: 100% !important;
  }

  .demoCustomLine.vertical-timeline::before{
    width: 3px;
  }

  #toolbar {
    display: flex;
    align-items: center;
  }
  
  #toolbar select {
    margin-right: 10px; /* Add some space between dropdown and buttons */
  }
  