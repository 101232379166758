.editor-container {
        /* border: 1px solid #ccc; 
        border-radius: 4px; 
        padding: 4px; */
  }
  
  .editor-container .ql-editor {
    height: 200px; /* Initial height */
    max-height: 600px; /* Optional max height */
    min-height: 100px; /* Optional min height */
    resize: vertical; /* Allow resizing of the editor textarea */
    overflow: auto; /* Scroll when content overflows */
    padding: 10px; /* Add padding for better UX */
    /* border: 1px solid #ccc;  */
    border-radius: 4px; /* Match container styling */
    box-sizing: border-box; /* Include padding in height calculations */
  }
  