@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
body {
  font-family: "DM Sans", sans-serif;
}

option {
  color: black;
}

.ql-size{
  border: 1px solid #ccc;
}

.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {

  height: 27px !important;
  width: auto !important;
}